/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';

import { APIWOI } from 'api';
import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';

import { Link } from 'gatsby';
import { RightOutlined } from '@ant-design/icons';
import CaliceoLink from 'components/buttons/CaliceoLink';

import useWindowWidth from 'hooks/use-window-width';

const REASSURANCE_ITEM_QUERY = gql(`
query CMSPageQuery($nid: [String]) {
  nodeQuery(filter: { conditions: [{ field: "nid", value: $nid }] }) {
    entities {
      ... on NodeArticle {
        nid
        title
        fieldAccrocheTexte {
          processed
        }
        fieldAccrocheImage {
          entity {
            ... on MediaImage {
              fieldMediaImage {
                title
                url
              }
            }
          }
        }
        fieldLienContenu
      }
    }
  }
}`);

const ReassuranceItem = (props) => {
  const { url, cid } = props;
  const [cmsPageId, setCmsPageId] = useState('');
  const [reassuranceData, setReassuranceData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(0);

  useWindowWidth({ setWindowWidth });

  const getPageId = (alias) => APIWOI.get(`/fuopefjeh?path=${alias}&cid=99`).then((res) => {
    if (res.data.status === 1) {
      setCmsPageId(res.data.responseData.id);
    }
  });

  useEffect(() => {
    getPageId(url);
  }, []);

  const [getCMSArticle] = useLazyQuery(REASSURANCE_ITEM_QUERY, {
    returnPartialData: true,
    onCompleted: (completedData) => setReassuranceData(completedData),
  });

  useEffect(() => {
    if (cmsPageId.length > 0) {
      getCMSArticle({
        variables: {
          nid: cmsPageId,
        },
      });
    }
  }, [cmsPageId]);

  if (!reassuranceData?.nodeQuery) {
    return null;
  }

  if (windowWidth < 768) {
    return (
      <div className="reinsurance-link">
        {reassuranceData?.nodeQuery.entities[0].title}
        <RightOutlined className="reinsurance-link--icon" />
      </div>
    );
    // return (
    //   <Link className="reinsurance-link" to={url}>
    //     {reassuranceData?.nodeQuery.entities[0].title}
    //     <RightOutlined className="reinsurance-link--icon" />
    //   </Link>
    // );
    //
  }

  return (
    <div className="reinsurance-card">
      <div className="reinsurance-card-image--wrapper">
        <img
          className="reinsurance-card-image"
          src={reassuranceData?.nodeQuery.entities[0].fieldAccrocheImage?.entity?.fieldMediaImage?.url}
          alt={reassuranceData?.nodeQuery.entities[0].title}
        />
      </div>
      <h3 className="reinsurance-card-title">{reassuranceData?.nodeQuery.entities[0].title}</h3>
      {reassuranceData?.nodeQuery.entities[0].fieldAccrocheTexte?.processed ? (
        <p className="reinsurance-card-desc" dangerouslySetInnerHTML={{ __html: reassuranceData?.nodeQuery.entities[0].fieldAccrocheTexte?.processed }} />
      ) : (
        <p className="reinsurance-card-desc">{reassuranceData?.nodeQuery.entities[0].fieldAccrocheTexte}</p>
      )}
      {reassuranceData?.nodeQuery.entities[0].fieldLienContenu ? (
        <CaliceoLink
          primaryButton
          url={url}
          classNames="reinsurance-card-link"
        />
      ) : null}
    </div>
  );
};

export default ReassuranceItem;
