import { useStaticQuery, graphql } from 'gatsby';

const useReinsuranceContent = () => {
  const { caliceo } = useStaticQuery(graphql`
    query {
      caliceo {
        blockContentById(id: "9") {
          ... on caliceo_BlockContentReferencesSimples {
            entityId
            fieldTitre
            fieldContenuLien {
              url {
                path
              }
              uri
            }
          }
        }
      }
    }
  `);

  return {
    title: caliceo.blockContentById.fieldTitre,
    reinsuranceItems: caliceo.blockContentById.fieldContenuLien.map(
      (reinsuranceItem) => ({
        url: reinsuranceItem.url.path,
      }),
    ),
  };
};

export default useReinsuranceContent;
