import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Layout from 'components/layout';
import Login from 'components/login';
import Reinsurance from 'components/home-group/reinsurance/Reinsurance';
import userIsLoggedIn from 'utils/checkLoggedIn';
import { getCenterName } from 'utils/location';
import { centerCookieIds } from 'utils/constants';

const LoginPage = (props) => {
  const centerName = getCenterName(props.location);

  useEffect(() => {
    if (userIsLoggedIn(`centre-${centerName}`)) {
      navigate('/mon-compte');
    } else {
      localStorage.removeItem(centerCookieIds[`centre-${centerName}`]?.cookieName);
    }
  }, [centerName]);

  return (
    <Layout location={props.location}>
      <Login centerId={`centre-${centerName}`} />
      <Reinsurance location={props.location} />
    </Layout>
  );
};

export default LoginPage;
