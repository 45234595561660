import React, { useState, useEffect } from 'react';

import CaliceoRocksIcon from 'images/icons/caliceo-rocks.svg';
import useReinsuranceContent from 'hooks/use-resinsurance-content';
import { getCenterName } from 'utils/location';
import useWindowWidth from 'hooks/use-window-width';
import { centerCookieIds } from 'utils/constants';
import ReassuranceItem from './ReassuranceItem';
import './reinsurance.scss';

const Reinsurance = (location) => {
  const [cid, setCid] = useState('');
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const centerName = getCenterName(location);
    if (centerName) {
      setCid(centerCookieIds[`centre-${centerName}`].cid);
    }
  }, [location]);

  const {
    title,
    reinsuranceItems,
  } = useReinsuranceContent();
  useWindowWidth({ setWindowWidth });

  if (!cid) return null;

  return (
    <div className="container reinsurance-section">
      <img className="reinsurance-section-icon" src={CaliceoRocksIcon} alt="" />
      <h2 className="reinsurance-section-title">{title}</h2>

      {windowWidth < 768 ? (
        <div className="reinsurance-links">
          {reinsuranceItems.map((reinsuranceItem) => (
            <ReassuranceItem cid={cid} key={reinsuranceItem.url} url={reinsuranceItem.url} />
          ))}
        </div>
      ) : (
        <div className="reinsurance-cards--wrapper">
          {reinsuranceItems.map((reinsuranceItem) => (
            <ReassuranceItem cid={cid} key={reinsuranceItem.url} url={reinsuranceItem.url} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Reinsurance;
