import React, { useEffect, useState } from 'react';

import { getCenterById } from 'utils/getCentersBy';

import SelectYourCenter from 'components/select-center/SelectYourCenter';
import LoginForm from './LoginForm';

import './login.scss';

const Login = (props) => {
  const { centers, location, centerId } = props;

  const [centerObj, setCenterObj] = useState(null);

  useEffect(() => {
    if (!['localhost', 'caliceo'].some((con) => centerId.includes(con))) {
      setCenterObj(getCenterById(centers, centerId));
    }
  }, [centers, location, centerId]);

  return (
    <div>
      <div className="login-page--header">
        {centerObj && (
          <h3>{centerObj?.name}</h3>
        )}
        <h1>Entrez dans la bulle Calicéo</h1>
      </div>
      {
        centerObj
          ? <LoginForm selectedCenter={centerObj} />
          : <SelectYourCenter centers={centers} location={location} />
      }
    </div>
  );
};

export default Login;
