import { useEffect } from 'react';

import Cookies from 'universal-cookie';
import { getCookie } from 'api';

import { centerCookieIds } from 'utils/constants';

const cookies = new Cookies();

const useSetCenterCookie = (centerId) => {
  useEffect(() => {
    if (centerId && !['localhost', 'caliceo'].some((s) => centerId.includes(s))) {
      const hasCookieForCenter = cookies.get(
        centerCookieIds[centerId]?.cookieName,
      );
      if (!hasCookieForCenter) {
        getCookie.get(`?cid=${centerCookieIds[centerId].cid}`).then((res) => {
          cookies.set(
            `${centerCookieIds[centerId].cookieName}`,
            res.data.responseData.userContent,
            { path: '/' },
          );
        });
      }
    }
  }, [centerId]);
};

export default useSetCenterCookie;
