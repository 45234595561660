import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setUserLoggedIn } from 'redux/actions/auth';

import { selectLastItemUrl } from 'redux/selectors/cart';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({
    setUserLoggedIn,
  }, dispatch),
});

const mapStateToProps = (state) => ({
  lastItemUrl: selectLastItemUrl(state),
});

export default connect(mapStateToProps, mapDispatchToProps);
