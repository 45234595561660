import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import qs from 'qs';
import { Form, Input } from 'antd';
import Cookies from 'universal-cookie';
import useSetCenterCookie from 'hooks/use-set-center-cookie';
import API from 'api';
import CaliceoButton from 'components/buttons/CaliceoButton';
import { centerCookieIds } from 'utils/constants';

const LoginForm = (props) => {
  const { selectedCenter, actions, lastItemUrl } = props;
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const cookies = new Cookies();
  const [loginSubmitting, setLoginSubmitting] = useState(false);
  useSetCenterCookie(selectedCenter.centerId);

  const onSubmit = (values) => {
    const loginValues = {
      ...values,
      session: cookies.get(centerCookieIds[selectedCenter.centerId].cookieName),
    };
    setLoginSubmitting(true);
    API.post('/otjhoj', qs.stringify(loginValues), { centerId: selectedCenter.centerId }).then((res) => {
      if (res.data.status === 1) {
        cookies.set(centerCookieIds[selectedCenter.centerId].cookieName, res.data.responseData.userContent.session, { path: '/' });
        localStorage.setItem(centerCookieIds[selectedCenter.centerId].cookieName, res.data.responseData.userContent.session);
        localStorage.setItem('userOid', res.data.responseData.userContent.oid);
        localStorage.setItem('user_center', res.data.responseData.userContent.center);
        setErrorMessage('');
        actions.setUserLoggedIn(res.data.responseData.userContent);

        if (lastItemUrl) {
          navigate(lastItemUrl);
        } else {
          navigate('/mon-compte');
        }
      }
      if (res.data.status === 0) {
        setErrorMessage('La connexion a échoué');
      }
      setLoginSubmitting(false);
    });
  };

  return (
    <div className="login-form--section">
      <div className="login-form--wrapper">
        <div className="login-form">
          <h3 className="login-form--title">Se connecter</h3>
          <Form layout="vertical" form={form} name="control-hooks" onFinish={onSubmit}>
            <Form.Item
              name="username"
              label="Email"
              validateTrigger="onSubmit"
              rules={
                [
                  { required: true, message: 'Le champ email n\'est pas saisi' },
                  { type: 'email', message: 'L\'entrée n\'est pas un e-mail valide' },
                ]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Mot de passe"
              validateTrigger="onSubmit"
              rules={[{ required: true, message: 'Le champ mot de passe n\'est pas saisi' }]}
            >
              <Input.Password />
            </Form.Item>
            <Link to="/reset-password">Mot de passe oublié ?</Link>
            {errorMessage && <p className="login-error-message">{errorMessage}</p>}
            <Form.Item>
              <CaliceoButton
                primaryButton
                showIcon={false}
                classNames="mx-auto mt-5"
                title="Se connecter"
                loading={loginSubmitting}
                disabled={loginSubmitting}
                type="submit"
              />
            </Form.Item>
            <Link to="/register">
              Nouveau client Calicéo ? Créez votre compte
            </Link>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
