import React from 'react';
import { navigate } from 'gatsby';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { getHostname } from 'utils/location';
import forwardToCenter from 'utils/forwardToCenter';

import './selectYourCenter.scss';

const SelectYourCenter = (props) => {
  const hostname = getHostname(props.location);

  const centersMenu = (
    <Menu>
      {props.centers.map((center) => (
        <Menu.Item
          key={center.centerId}
          onClick={() => forwardToCenter(props.centers, center.centerId, hostname)}
        >
          {center.name}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="select-center--wrapper">
      <div className="container">
        <Dropdown className="mx-auto my-5" overlay={centersMenu} trigger={['click']}>
          <button type="button" className="dropdown-trigger--btn" onClick={(e) => e.preventDefault()}>
            Merci de choisir votre centre
            {' '}
            <DownOutlined />
          </button>
        </Dropdown>
      </div>
    </div>
  );
};

export default SelectYourCenter;
